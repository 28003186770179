import React from 'react';
import "./Images.css";

const ShowImage = ({ data, Role, Payload }) => {
    return (
        <div className="grid-container">
        {Role === "Reviewer"
          ? data?.map((item) => (
              <div key={item.Id} className="grid-item">
                <img
                  src={`data:image/jpeg;base64,${item.ImageData}`}
                  alt="Review"
                  className="image"
                />
              </div>
            ))
          : Payload?.map((item) => (
              <div key={item.Id} className="grid-item">
                <img
                  src={`data:image/jpeg;base64,${item.ImageData}`}
                  alt="Payload"
                  className="image"
                />
              </div>
            ))}
      </div>
    );
};

export default ShowImage;
